<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Data Ofisial
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <router-link to="/pages/ofisial-tambah">
          <b-button variant="primary" class="btn btn-add btn-sm mb-2 px-4">
            Tambah Ofisial</b-button
          >
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body" v-if="!loading">
            <div class="row">
              <div class="col-sm-6 col-md-9 mt-2">
                <div class="d-flex align-items-center mr-2">
                  Tampilkan : &nbsp;
                  <select
                    class="form-control"
                    style="width: 70px"
                    v-model="pagination.per_page"
                    @change="listOfisial(pagination.curr_page)"
                  >
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-3 mt-2">
                <div class="d-flex">
                  <input
                    type="text"
                    class="form-control pr-1"
                    placeholder="Cari ofisial.."
                    v-model="form.search"
                    @change="listOfisialSearch(1)"
                    @keyup.enter="listOfisialSearch(1)"
                  />
                  <span>
                    <button
                      class="btn btn-sm btn-primary h-100"
                      @click="listOfisial(1)"
                    >
                      <i class="fas fa-search-plus px-1" v-if="loading"></i>
                      <i class="fa fa-search px-0 m-0" v-else></i>
                    </button>
                  </span>
                </div>
              </div>
              <!-- End search -->
            </div>

            <div class="table-responsive mt-2" v-if="tableData.length > 0">
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <th width="5%" class="text-center">Foto</th>
                    <th width="22%">Nama Ofisial</th>
                    <th width="15%">TTL</th>
                    <th width="15%">Tgl Bergabung</th>
                    <th width="15%">Jabatan</th>
                    <th>Lisensi</th>
                    <th class="text-center" width="7%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in tableData" :key="idx">
                    <td class="text-center">
                      {{ pagination.from + idx }}
                    </td>
                    <td class="text-center">
                      <img :src="url_img + list.img" class="w-8" height="30" />
                    </td>
                    <td>
                      {{ list.nama }}
                    </td>
                    <td>{{ list.tempat_lahir }}, {{ list.tanggal_lahir }}</td>
                    <td>{{ list.tgl_bergabung }}</td>
                    <td>{{ list.posisi.nama_posisi }}</td>
                    <td>{{ list.lisensi }}</td>
                    <td class="text-center">
                      <span class="mr-1 bg-success m-1">
                        <router-link :to="'ofisial-ubah?idp=' + list.id">
                          <b-button
                            title="Edit ofisial"
                            class="btn btn-sm px-2 py-1"
                            variant="secondary"
                            ><i class="fa fa-pencil"></i
                          ></b-button>
                        </router-link>
                      </span>
                      <span class="mr-1">
                        <b-button
                          @click="deleteOfisial(list.id, list.nama)"
                          title="Delete ofisial"
                          class="btn btn-sm px-2 py-1"
                          variant="danger"
                          ><i class="fa fa-trash"></i
                        ></b-button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive mt-2" v-else>
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <th width="5%" class="text-center">Foto</th>
                    <th width="30%">Nama Ofisial</th>
                    <th width="20%">TTL</th>
                    <th width="15%">Tgl Bergabung</th>
                    <th width="20%">Jabatan</th>
                    <th class="text-center" width="7%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7" class="text-center">Data tidak ada!</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <label class="d-inline-flex align-items-center mr-2">
                  Halaman {{ pagination.curr_page }} |
                </label>
                <span>
                  Menampilkan {{ pagination.from }} - {{ pagination.to }} dari
                  {{ pagination.total }} data
                </span>
              </div>
              <ul
                class="pagination pagination-rounded pagination-sm justify-content-end mb-2"
                v-if="pagination.total > 20"
              >
                <li
                  class="page-item"
                  :class="[
                    pg.active ? 'active' : '',
                    pg.url == null ? 'disabled' : 'hoverable',
                  ]"
                  v-for="(pg, pgi) in pagination.links"
                  :key="pgi"
                >
                  <a
                    class="page-link"
                    aria-label="Previous"
                    v-if="pg.label == 'Previous'"
                    @click="listOfisial(pagination.curr_page - 1)"
                  >
                    <i class="fa fa-chevron-left"></i>
                  </a>
                  <a
                    class="page-link"
                    aria-label="Next"
                    v-else-if="pg.label == 'Next'"
                    @click="listofisial(pagination.curr_page + 1)"
                  >
                    <i class="fa fa-chevron-right"></i>
                  </a>
                  <a class="page-link" v-else-if="pg.label == '...'">
                    {{ pg.label }}
                  </a>
                  <a class="page-link" @click="listOfisial(pg.label)" v-else>
                    {{ pg.label }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="card-body" v-else>
            <div class="row">
              <div class="col-12 py-5 my-5">
                <div v-if="loading" class="mt-5 mx-auto p-5">
                  <div class="text-center">
                    <div
                      class="spinner-border text-secondary"
                      otp_ocr="status"
                      v-if="loading"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ofisialService from "@/_services/ofisial.service";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      search: "",
      loading: false,
      isEdit: false,
      tableData: [],
      pagination: {
        links: [],
        curr_page: 0,
        last_page: 0,
        last_page: 0,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
      },
      params: "",
      form: {
        id: "",
        slug: "",
        nama: "",
        search: "",
      },

      submitted: false,
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },

  methods: {
    listOfisial(page) {
      this.params = "page=" + page + "&per_page=" + this.pagination.per_page;
      this.loading = true;
      ofisialService
        .get(this.params)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data.data;
          console.log("data", response.data.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id,
              nama: val.nama,
              img: val.img,
              posisi: val.posisi,
              tgl_bergabung: val.tgl_bergabung,
              tempat_lahir: val.tempat_lahir,
              tanggal_lahir: val.tanggal_lahir,
              lisensi: val.lisensi,
              created_at: val.created_at,
            };
            this.tableData.push(data);
          });
          this.pagination.links = response.data.data.links;
          this.pagination.curr_page = response.data.data.current_page;
          this.pagination.last_page = response.data.data.last_page;
          this.pagination.per_page = response.data.data.per_page;
          this.pagination.from = response.data.data.from;
          this.pagination.to = response.data.data.to;
          this.pagination.total = response.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    listOfisialUpdate(page) {
      this.params = "page=" + page + "&per_page=" + this.pagination.per_page;
      ofisialService
        .get(this.params)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data.data;
          console.log("data", response.data.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              o: index + 1,
              id: val.id,
              nama: val.nama,
              img: val.img,
              posisi: val.posisi,
              tgl_bergabung: val.tgl_bergabung,
              tempat_lahir: val.tempat_lahir,
              tanggal_lahir: val.tanggal_lahir,
              lisensi: val.lisensi,
              created_at: val.created_at,
            };
            this.tableData.push(data);
          });
          this.pagination.links = response.data.data.links;
          this.pagination.curr_page = response.data.data.current_page;
          this.pagination.last_page = response.data.data.last_page;
          this.pagination.per_page = response.data.data.per_page;
          this.pagination.from = response.data.data.from;
          this.pagination.to = response.data.data.to;
          this.pagination.total = response.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    listOfisialSearch(page) {
      this.params =
        "search=" +
        this.form.search +
        "&page=" +
        page +
        "&per_page=" +
        this.pagination.per_page;
      this.loading = true;
      // console.log("param", this.params);
      this.loading = true;
      ofisialService
        .get(this.params)
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data.data;
          console.log("data", response.data.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              o: index + 1,
              id: val.id,
              nama: val.nama,
              img: val.img,
              posisi: val.posisi,
              tgl_bergabung: val.tgl_bergabung,
              tempat_lahir: val.tempat_lahir,
              tanggal_lahir: val.tanggal_lahir,
              lisensi: val.lisensi,
              created_at: val.created_at,
            };
            this.tableData.push(data);
          });
          this.pagination.links = response.data.data.links;
          this.pagination.curr_page = response.data.data.current_page;
          this.pagination.last_page = response.data.data.last_page;
          this.pagination.per_page = response.data.data.per_page;
          this.pagination.from = response.data.data.from;
          this.pagination.to = response.data.data.to;
          this.pagination.total = response.data.data.total;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    deleteOfisial(id, name) {
      Swal.fire({
        title: "Apakah anda yakin ingin menghapus data ini?",
        text: name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#153F37",
        cancelButtonColor: "#eeeeee",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("id", id);
          ofisialService
            .delete(id)
            .then((response) => {
              this.listOfisialUpdate(this.pagination.curr_page);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log("err ", err);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Gagal",
                text: "Hapus ofisial Gagal",
                showConfirmButton: false,
                timer: 2500,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.listOfisial(1);
  },
};
</script>

<style>
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
